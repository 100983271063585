import createRequest from '@/utils/request'
import API_PREFIX from '@/config/api.prefix'
// @params:  baseUrl, accessKey, secretKey
const request = createRequest(API_PREFIX.file_base_url)

/*
    图片上传接口
    @params
*/
export const uploadFileApi = API_PREFIX.file_base_url + '/minio-oss/fileUpload'


// 获取图片地址 - 废弃
export function getPreSignedObjectUrl (parameter) {
  return request({
    url: '/minio-oss/getPreSignedObjectUrlByFileIdentification',
    method: 'post',
    data: parameter,
  })
}