<template>
  <a-upload
    :headers="headers"
    :before-upload="beforeUpload"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </a-upload>
</template>

<script>
import {getSign, createNonce} from '../utils/tools'
import apiKeys from '@/config/api.key'
export default {
  name: 'FileUpload',
  props: {
    limit: {
      type: Object
    },
    customBeforeUpload: {
      type: Function
    }
  },
  data() {
    return {
      headers: {
        'X-validate-accessKey': '',
        'X-validate-timestamp': '',
        'X-validate-nonceStr': '',
        'X-validate-sign': '',
      }
    }
  },
  created() {
    console.log(this.$attrs)
  },
  methods: {
    beforeUpload(file, fileList) {
      console.log('beforeUpload', file, fileList)

      // 上传图片限制
      if (this.limit && typeof this.limit == 'object') {
        let type = this.limit.type || {}
        if (type.reg instanceof RegExp && !type.reg.test(file.type)) {
          this.$message.error(type.msg || '图片格式不符合要求')
          return false
        }

        let size = this.limit.size || {}
        if (typeof size.max === 'number' && file.size / 1024 /1024 > size.max) {
          this.$message.error(size.msg || '图片大小不符合要求')
          return false
        }
      }
      

      // 上传参数预处理
      let timestamp = Date.now()
      let nonce = createNonce()
      let signObj = {
        'X-validate-accessKey': apiKeys.accessKey,
        'X-validate-timestamp': timestamp,
        'X-validate-nonceStr': nonce
      }
      let sign = getSign(signObj, apiKeys.secretKey)

      this.headers = {
        'X-validate-accessKey': apiKeys.accessKey,
        'X-validate-timestamp': timestamp,
        'X-validate-nonceStr': nonce,
        'X-validate-sign': sign,
      }

      // 自定义处理函数
      if (this.customBeforeUpload) return this.customBeforeUpload(file, fileList)
    }
  }
}
</script>

<style>

</style>