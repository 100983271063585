<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
    </template>

    <a-spin :spinning="loading">
      <div class="mp-detail-module">
        <div class="module-top">
          <h3 class="mod-ttile">基础信息</h3>
          <div class="mod-handles">
            <a-button type="link" size="small" @click="saveBasicInfo">保存</a-button>
          </div>
        </div>
        <div class="module-main">
          <a-form-model
            ref="editForm"
            :model="editForm"
            :rules="editRules"
            layout="vertical"
          > 
            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="企业名称" prop="companyName">
                  <a-input style="width: 80%" v-model="editForm.companyName" placeholder="请输入企业名称" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="企业类型" prop="companyType">
                  <a-radio-group v-model="editForm.companyType">
                    <a-radio :value="1">
                      工商型
                    </a-radio>
                    <a-radio :value="2">
                      个人型
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8" v-if="editForm.companyType == 1">
              <a-col :span="12">
                <a-form-model-item label="营业执照号码" prop="companyLicenseNumber">
                  <a-input style="width: 80%" v-model="editForm.companyLicenseNumber" placeholder="请输入营业执照号码" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="营业执照照片" required>
                  <span style="cursor:pointer; color: #666; margin-right: 15px" v-if="editForm.companyLicenseImageUrl" @click="viewBigImage">点击查看营业执照照片</span>
                  <file-upload
                    name="files"
                    :multiple="false"
                    :data="uploadData"
                    :action="uploadFileApi"
                    :show-upload-list="false"
                    @change="handleUploadChange"
                    accept="image/*"
                    :limit="{
                      type: {
                        reg: /^image\/.*/,
                        msg: '请上传图片格式的图片'
                      },
                      size: {
                        max: 20,
                        msg: '图片的大小不能超过20M'
                      }
                    }"
                    :custom-before-upload="customBeforeUpload"
                  >
                    <a-button type="link" style="padding: 0">{{!editForm.companyLicenseImageUrl ? '点击上传营业执照图片': '重新上传'}}</a-button>
                  </file-upload>
                  <!-- <a-button type="link" @click="$message.info('图片上传功能开发中...')">点击上传营业执照图片</a-button> -->
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="负责人姓名" prop="companyChargePerson">
                  <a-input style="width: 80%" v-model="editForm.companyChargePerson" placeholder="请输入负责人姓名" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="负责人身份证号" prop="companyChargePersonIdNumber">
                  <a-input style="width: 80%" v-model="editForm.companyChargePersonIdNumber" placeholder="请输入负责人身份证号" />
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="公司地址" prop="companyAddress">
                  <a-cascader style="width: 30%;" class="address-cascader"
                    :field-names="{ label: 'region_name', value: 'region_id', children: 'children' }"
                    :options="addressOptions"
                    :load-data="loadAddressData"
                    change-on-select 
                    placeholder="省/市/区(县)"
                    @change="onAddressChange"
                    :getPopupContainer="node => node.parentNode || document.body"
                    v-model="editForm.companyRegions"
                  />
                  <a-input style="width: 60%; border-radius: 0 2px 2px  0; border-left-color: #eee;" v-model="editForm.companyAddress" placeholder="请输入公司地址"
                    @blur="() => {
                      $refs['editForm'].validateField('companyAddress')
                    }"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="工厂地址（选填）">
                  <a-cascader style="width: 30%;" class="address-cascader" 
                    :field-names="{ label: 'region_name', value: 'region_id', children: 'children' }"
                    :options="addressOptions"
                    :load-data="loadAddressData"
                    change-on-select 
                    placeholder="省/市/区(县)"
                    @change="onAddressChange"
                    :getPopupContainer="node => node.parentNode || document.body"
                    v-model="editForm.factoryRegions"
                  />
                  <a-input style="width: 60%; border-radius: 0 2px 2px  0; border-left-color: #eee;" v-model="editForm.factoryAddress" placeholder="请输入公司地址" />
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-model-item label="联系人员" prop="companyContactPerson">
                  <a-input style="width: 80%" v-model="editForm.companyContactPerson" placeholder="请输入联系人员" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="联系电话" prop="companyContactPhone">
                  <a-input style="width: 80%" v-model="editForm.companyContactPhone" placeholder="请输入联系电话" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="8">
              <a-col :span="24">
                <a-form-model-item label="业务类型（选填）">
                  <a-checkbox-group v-model="editForm.businessTypes">
                    <a-checkbox :value="item.id" name="businessType" v-for="item in templateList" :key="item.id">
                      {{item.name}}
                    </a-checkbox>
                  </a-checkbox-group>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </a-spin>
    <!-- 查看大图 -->
    <a-modal v-model="imgVisible" title="营业执照照片(以下为演示图片)" centered :width="800" :footer="null">
      <img style="width: 100%;" :src="viewImgUrl">
    </a-modal>
  </page-head-wrapper>
</template>

<script>
import { Empty } from 'ant-design-vue'
import { addApprovedCompany } from '@/api/company'
import { templateList } from '@/api/system'
import { regionInfo } from '@/api/address'
import { uploadFileApi } from '@/api/upload'
import { mapGetters } from 'vuex'
import { validateMobile, validateIdcard } from '@/utils/validate'
import FileUpload from '@/components/FileUpload.vue' 
import { buildThumborUrl } from '@/utils/tools'

export default {
  name: 'static-data-company-maintenance-add',
  keep_alive_tab: true,
  components: {
    FileUpload
  },
  data() {
    let validPhone = (rule, value, callback) => {
      // console.log(rule, value, callback)
      value = value.trim()
      if (value === '') {
          callback(new Error('请输入手机号'))
      } else if (!validateMobile(value)) {
          callback(new Error('手机号格式不正确'))
      } else {
          callback()
      }
    }
    let validIdcard = (rule, value, callback) => {
      // console.log(rule, value, callback)
      value = value.trim()
      if (value === '') {
          callback(new Error('请输入身份证号码'))
      } else if (!validateIdcard(value)) {
          callback(new Error('身份证号码格式不正确'))
      } else {
          callback()
      }
    }

    let validCompanyAddress = (rule, value, callback) => {
      if (this.editForm.companyRegions.length<=0) {
        callback(new Error('请选择区域'))
      } else if (this.editForm.companyAddress.trim() == '') {
        callback(new Error('请填写详细地址'))
      } else {
        callback()
      }
    }
    return {
      // 当前id 和 路由
      id: '',
      currentPath: '',

      // 空状态图片类型
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,

      uploadFileApi,
      uploadData: {
        bucketName: 'yijiajia-company-license'
      },
      
      // 基础信息编辑
      loading: false,
      imgVisible: false,

      viewImgUrl: '',

      businessTypesArr: [],
      businessTypesStr: '',

      editForm: {
        companyName: '',
        companyType: '',
        companyChargePerson: '',
        companyChargePersonIdNumber: '',
        companyContactPerson: '',
        companyContactPhone: '',
        businessTypes: [],

        companyLicenseNumber: '',
        companyLicenseImage: '',
        companyLicenseImageUrl: '',

        companyAddress: '',
        companyRegions: [],

        factoryAddress: '',
        factoryRegions: [],
      },
      editRules: {
        companyName: [
          {required: true, message: '请输入企业名称', trigger: 'blur'}
        ],
        companyType: [
          {required: true, message: '请选择企业类型', trigger: 'change'}
        ],
        companyLicenseNumber: [
          {required: true, message: '请输入营业执照号码', trigger: 'blur'}
        ],
        companyChargePerson: [
          {required: true, message: '请输入负责人姓名', trigger: 'blur'}
        ],
        companyChargePersonIdNumber: [
          {required: true, message: '请输入负责人身份证号码', trigger: 'blur'},
          {validator: validIdcard, trigger: 'blur'}
        ],
        companyContactPerson: [
          {required: true, message: '请输入联系人员姓名', trigger: 'blur'}
        ],
        companyContactPhone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {validator: validPhone, trigger: 'blur'}
        ],

        companyAddress: [
          {required: true, validator: validCompanyAddress, trigger: 'change'}
        ],
      },
      addressOptions: [],
      templateList: [],
    }
  },
  computed: {
		...mapGetters(['adminId'])
	},
  created() {
    // this.id = this.$route.query.id
    // this.currentPath = this.$route.fullPath
    // this.uploadData.imageUserId = this.id
    // this.approvedCompanyDetail()
    this.getRegionsData('0')
    this.getTemplateList()
  },
  methods: {
     // 查看营业执照大图
    viewBigImage() {
      this.imgVisible = true
      this.viewImgUrl = this.editForm.companyLicenseImageUrl
    },
    // 获取业务类型列表
    getTemplateList() {
      templateList({parentId: '', type: 1}).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.templateList = res.data.filter(item => item.status == 1)
        } else {
          this.$message.error(res.msg || '获取业务类型出错')
        }
      }).catch(err => {
        console.warn(err)
      })
    },
    // 获取地址数据
    getRegionsData(id) {
      return new Promise((resolve, reject) => {
        regionInfo({
          parentId: id
        }).then(res => {
          console.log(res)
          if (res.errorCode == 200) {
            if (id == '0') {
              this.addressOptions = res.data.map(item => ({...item, isLeaf: false}))
            }
            resolve(res.data)
          } else {
            this.$message.error(res.errorMsg || '获取地址信息失败')
            reject(res)
          }
        }).catch(err => {
          console.warn(err)
          reject(err)
        })
      })
    },
    // 选择省市区时触发
    loadAddressData(selectedOptions) {
      console.log('targetOption', selectedOptions, targetOption)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      
      this.getRegionsData(targetOption.region_id).then(data => {
        targetOption.loading = false
        if (selectedOptions.length <= 1) {
          targetOption.children = data.map(item => ({...item, isLeaf: false}))
        } else {
          targetOption.children = data
        }
        this.addressOptions = [...this.addressOptions];
      })
    },
    // 选择省市区时触发
    onAddressChange(value) {
      console.log(value)
      console.log(this.editForm.companyRegions)
    },

    // 上传文件之前
    customBeforeUpload() {
      this.$showLoading()
    },
    // 上传图片
    handleUploadChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        let response = info.file.response
        if (response.code == 200) {
          this.$hideLoading()
          this.$message.success('上传成功')
          this.editForm.companyLicenseImage = response.data[0]
          this.editForm.companyLicenseImageUrl = buildThumborUrl(response.data[0])
        } else {
          this.$message.error(response.msg || '上传失败')
        }
      } else if (info.file.status === 'error') {
        this.$hideLoading()
        this.$message.error('上传失败')
      }
    },

    // 保存基础信息
    saveBasicInfo() {
      console.log(this.editForm)

      this.$refs['editForm'].validate(valid => {
        if (valid) {
          let params = {
            adminId: this.adminId,

            companyName: this.editForm.companyName,

            companyChargePerson: this.editForm.companyChargePerson,
            companyChargePersonIdNumber: this.editForm.companyChargePersonIdNumber,

            companyContactPerson: this.editForm.companyContactPerson,
            companyContactPhone: this.editForm.companyContactPhone,

            companyType: this.editForm.companyType,

            companyProvince: this.editForm.companyRegions && this.editForm.companyRegions[0] || '',
            companyCity: this.editForm.companyRegions && this.editForm.companyRegions[1] || '',
            companyDistrict: this.editForm.companyRegions && this.editForm.companyRegions[2] || '',
            companyAddress: this.editForm.companyAddress,

            factoryProvince: this.editForm.factoryRegions && this.editForm.factoryRegions[0] || '',
            factoryCity: this.editForm.factoryRegions && this.editForm.factoryRegions[1] || '',
            factoryDistrict: this.editForm.factoryRegions && this.editForm.factoryRegions[2] || '',
            factoryAddress: this.editForm.factoryAddress,

            businessTypes: this.editForm.businessTypes,
            
          }
          if (this.editForm.companyType == 1) {
            params.companyLicenseNumber = this.editForm.companyLicenseNumber
            params.companyLicenseImage = this.editForm.companyLicenseImage  
          }
          console.log(params)
          this.$showLoading()
          addApprovedCompany(params).then(res => {
            this.$hideLoading()
            console.log(res)
            if (res.code == 200) {
              this.$message.success('保存成功')
              if (res.data) {
                this.closeTabPage()
                this.pushTabPage({
                  path: '/static-data/company/maintenance/detail?id=' + res.data, 
                })
              } else {
                this.$refs.editForm.resetFields()
              }
            } else {
              this.$message.error(res.msg || '网络错误')
            }
          }).catch(err => {
            this.$hideLoading()
            console.warn(err)
          })
        }
      })
    },

    // 刷新操作
    onRefresh() {
      this.$refs.editForm.resetFields()
    },
  }
}
</script>

<style lang="less">
.address-cascader {
  border-radius: 2px 0 0 2px;
  .ant-input {
    border-right-color: #eee;
    border-radius: 2px 0 0 2px;
  }
}
</style>